import {useTranslation} from '@wix/yoshi-flow-editor'
import React from 'react'
import {SocialBar} from '../../../../social-bar'
import s from './share.scss'

export const Share = ({event}: {event: wix.events.Event}) => {
  const {t} = useTranslation()
  return (
    <div className={s.container}>
      <SocialBar t={t} spacing={24} event={event} row />
    </div>
  )
}
