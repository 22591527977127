import classNames from 'classnames'
import React from 'react'
import sc from '../../../../classes.scss'
import {LinkToPage} from '../../../../link-to-page'
import sd from '../../../common/mobile-layout.scss'
import s from './title.scss'
import {TitleProps} from '.'

export const Title = ({event}: TitleProps) => {
  const classes = classNames(s.root, sc.textLineHeight, sd.eventNameFont, sd.eventNameColor)

  return (
    <span className={classes} data-hook="ev-list-item-title">
      <LinkToPage event={event} allowExternal>
        {event.title}
      </LinkToPage>
    </span>
  )
}
