import {isDateTbd} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import React from 'react'
import {useEventDateInformation} from '../../../../../../../../commons/hooks/dates'
import {useVisibilityStyles} from '../../../../../hooks/use-visibility-styles'
import sd from '../../../../list-layout/item/date/date.scss'
import sc from '../../../common/mobile-layout.scss'
import s from './date.scss'
import {DateProps} from './interfaces'

export const Date = ({event, listLayout}: DateProps) => {
  const {
    allBreakpoints: {isListDateEnabled},
  } = useVisibilityStyles()
  const {shortStartDate} = useEventDateInformation(event.id)
  const dateTbd = isDateTbd(event)

  if (!isListDateEnabled()) {
    return null
  }

  return (
    <div
      className={classNames(
        s.date,
        listLayout ? sd.listHeaderDateTimeFont : sc.eventNameFont,
        listLayout ? sd.listHeaderDateTimeColor : sc.eventNameColor,
      )}
      data-hook={dateTbd ? 'ev-date-tbd' : 'ev-date'}
    >
      {shortStartDate}
    </div>
  )
}
